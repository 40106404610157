<template>
  <section>


    <div v-if="loading" class="circle-loader"></div>
    <!-- Main content -->
    <div class="content" v-if="contato">
      <b-row>
        <b-col sm="12" md="3">
          <!-- Imagem do Contato -->
          <div class="card card-primary card-outline">
            <div class="card-body box-profile">
              <div class="text-center">
                <img
                  class="profile-user-img img-fluid img-circle"
                  src="@/assets/images/faces/profile/avatar5.png"
                  alt="Imagem do Contato"
                />
              </div>

              <h3 class="profile-username text-center">
                {{ contato.nome }}
              </h3>
              <p class="text-muted text-center">
                <template
                  v-if="contato.empresa && contato.empresa.nome_fantasia"
                >
                  {{ contato.empresa.nome_fantasia }}
                </template>
                <template
                  v-else-if="contato.empresa && contato.empresa.empresa_base"
                >
                  {{ contato.empresa.empresa_base.razao_social }}
                </template>
                <template v-else-if="contato.empresa_cnpj">
                  {{ contato.empresa_cnpj | cnpjFilter }}
                </template>
                <template v-else-if="contato.empresa_nome">
                  {{ contato.empresa_nome }}
                </template>
              </p>

              <div class="py-4">
                <p class="clearfix">
                  <span class="float-left">
                    <b><i class="fa fa-phone mr-1"></i> Telefones </b>
                  </span>
                  <span class="float-right text-muted">
                    {{ contato.contato_telefones.length }}
                  </span>
                </p>
                <p class="clearfix">
                  <span class="float-left">
                    <b><i class="fa fa-envelope mr-1"></i> Emails</b>
                  </span>
                  <span class="float-right text-muted">
                    {{ contato.contato_emails.length }}
                  </span>
                </p>
                <p v-if="contato.linkedin" class="clearfix">
                  <a
                    :href="contato.linkedin"
                    target="_blank"
                    class="btn btn-primary btn-block"
                    ><b><i class="fa fa-linkedin"></i> Linkedin</b></a
                  >
                  <span class="float-right text-muted"> </span>
                </p>
              </div>
            </div>
            <!-- /.card-body -->
          </div>
          <!-- /.card -->
        </b-col>

        <b-col sm="12" md="9">
          <div class="row">
            <div class="col-lg-12 grid-margin stretch-card">
              <b-card>
                <template #header>
                  <h6 class="mb-0">
                    <i class="fa fa-info mr-1"></i> Detalhes do Contato
                  </h6>
                </template>
                <b-card-body>
                  <b-row>
                    <b-col cols="4">
                      <dl>
                        <dt><i class="fa fa-address-card mr-1"></i> Cargo</dt>
                        <dd>{{ contato.cargo }}</dd>
                      </dl>
                    </b-col>
                    <b-col cols="4">
                      <dl>
                        <dt>
                          <i class="fa fa-university mr-1"></i> Departamento
                        </dt>
                        <dd>
                          <template v-if="contato.departamento">
                            {{ contato.departamento.nome }}
                          </template>
                        </dd>
                      </dl>
                    </b-col>
                    <b-col cols="4">
                      <dl>
                        <dt>
                          <i class="fa fa-sitemap mr-1"></i> Nível Hierárquico
                        </dt>
                        <dd>
                          <template v-if="contato.nivel_hierarquico">
                            {{ contato.nivel_hierarquico.nome }}
                          </template>
                        </dd>
                      </dl>
                    </b-col>
                  </b-row>
                </b-card-body>
              </b-card>
            </div>
          </div>
          <b-row>
            <div class="col-lg-6 grid-margin stretch-card">
              <b-card>
                <b-card-title>
                  <h6 class="mb-0">
                    <i class="fa fa-phone mr-1"></i> Telefones
                  </h6>
                </b-card-title>

                <table class="table">             
                 
                  <tbody class="text-muted">
                    <tr
                      v-for="telefone in contato.contato_telefones"
                      :key="telefone.id"
                    >

                      <td>

                        {{ telefone.telefone }}</td>


                        <td>                       

                         <i v-if="telefone.valido" class="icon-check text-success" v-b-tooltip.hover title="Válido"></i> 


                         <i v-else-if="telefone.valido === 0" class="icon-info text-danger" v-b-tooltip.hover title="Inválido"></i> 

                        
                         <i v-else class="icon-info text-warning " v-b-tooltip.hover title="Validação Pendente"></i>                       


                      </td>



                      <!-- <td class="text-right">
                        <button
                          class="btn btn-success mr-2"
                          v-on:click="call(telefone.telefone)"
                          v-b-tooltip.hover.top="'Clique para Ligar'"
                        >
                          <i class="icon-phone"></i>
                        </button>

                        <button
                          class="btn btn-success"
                          v-b-tooltip.hover.top="'Enviar Mensagem por WhatsApp'"
                          v-b-toggle.sidebar-for-send-whatsapp
                          v-on:click="setNumberForWhatsApp(telefone.telefone)"
                        >
                          <i class="mdi mdi-whatsapp"></i>
                        </button>
                      </td> -->

                    

                      
                    </tr>
                  </tbody>
                </table>

                        <!-- <label v class="badge badge-outline-warning badge-pill"> <i class="icon-info"></i></label> -->


                <!-- <b-badge v-else variant="outline-dark"
                          >Validação Pendente
                        </b-badge> -->

                <!-- <h1 v-for="telefone in contato.contato_telefones" :key="telefone.id">magico</h1> -->

                <!-- <b-list-group flush>
                  <b-list-group-item
                    class="d-flex justify-content-between align-items-center"
                    v-for="telefone in contato.contato_telefones"
                    :key="telefone.id"
                    variant="info"
                  >
                    {{ telefone.telefone }}

                    <button
                      class="btn btn-success"
                      v-on:click="call(telefone.telefone)"
                      v-b-tooltip.hover.top="'Clique para Ligar'"
                    >
                      <i class="icon-phone"></i>
                    </button>

                    <button
                      class="btn btn-success"
                      v-b-tooltip.hover.top="'Enviar Mensagem por WhatsApp'"
                      v-b-toggle.sidebar-for-send-whatsapp
                      v-on:click="setNumberForWhatsApp(telefone.telefone)"
                    >
                      <i class="mdi mdi-whatsapp"></i>
                    </button>

                    <b-badge v-if="telefone.valido" variant="success"
                      >Válido</b-badge
                    >
                    <b-badge v-else-if="telefone.valido === 0" variant="danger"
                      >Inválido</b-badge
                    >



                

                    <b-badge v-else variant="outline-dark"
                      >Validação Pendente
                      </b-badge>
                  </b-list-group-item>
                </b-list-group> -->
              </b-card>
            </div>
            <div class="col-lg-6 grid-margin stretch-card">
              <b-card>
                <b-card-title>
                  <h6 class="mb-0">
                    <i class="fa fa-envelope mr-1"></i> Emails
                  </h6>
                </b-card-title>

                <b-list-group flush>
                  <b-list-group-item
                    class="d-flex justify-content-between align-items-center"
                    v-for="email in contato.contato_emails"
                    :key="email.id"
                    variant="info"
                  >
                    <span class="text-muted">{{ email.email }}</span>

                    <b-badge v-if="email.valido" variant="success"
                      >Válido</b-badge
                    >

                    <i v-if="email.valido" class="icon-info text-success" v-b-tooltip.hover title="Válido"></i> 



                    <!-- <b-badge v-else-if="email.valido === 0" variant="danger"
                      >Inválido</b-badge
                    > -->

                    <i v-else-if="email.valido === 0" class="icon-info text-danger" v-b-tooltip.hover title="Inválido"></i> 



                    <!-- <b-badge v-else variant="outline-warning"
                      >Validação Pendente</b-badge
                    > -->
                    <i v-else class="icon-info text-warning" v-b-tooltip.hover title="Validação Pendente"></i> 

                    <!-- <button>aa</button> -->

                    <a :href="`mailto:${email.email}` " v-b-tooltip.hover title="Enviar email" class="btn btn-link "> <i class="mdi mdi-email-outline"></i> </a>



                  </b-list-group-item>

                  
                </b-list-group>

                
              </b-card>
            </div>
          </b-row>
        </b-col>
      </b-row>
    </div>

    <!-- <b-button v-b-toggle.sidebar-right>Toggle Sidebar</b-button> -->

    <!-- sidebar para whatsapp -->

    <b-sidebar
      id="sidebar-for-send-whatsapp"
      title="WhatsApp Sender"
      right
      shadow
      bg-variant="dark"
      text-variant="light"
    >
      <div class="px-3 py-2">
        <div class="d-flex justify-content-between">
          <span
            >Enviar mensagem WhatsApp para o número: <br />
            {{ numberWhatsApp }}</span
          >

          <span
            class="m-2"
            v-b-tooltip.hover.top="
              'Para iniciar uma conversa, o Whatsapp Business requer o uso de um Template '
            "
          >
            <i class="mdi mdi-help-circle-outline text-primary"></i>
          </span>
        </div>

        <form @submit.prevent="sendWhatsApp">
          <!-- <textarea
            placeholder=""
            wrap="soft"
            class="form-control mt-3"
            v-model="message"
            required
            disabled
          ></textarea> -->

          <div>
            {{ message }}
          </div>

          <b-form-select v-model="selected" class="mb-3 mt-2">
            <!-- This slot appears above the options from 'options' prop -->
            <template #first>
              <b-form-select-option :value="null" disabled
                >-- Selecione um Template --</b-form-select-option
              >
            </template>

            <!-- These options will appear after the ones from 'options' prop -->
            <!-- <b-form-select-option :value="options.name">{{options.name}}</b-form-select-option>
            <b-form-select-option value="D">Option D</b-form-select-option> -->

            <b-form-select-option
              v-for="item in options"
              :value="item.body"
              :key="item.name"
              >{{ item.name }}</b-form-select-option
            >
          </b-form-select>

          <!-- {{selected}} -->

          <div class="mt-2 text-right">
            <button class="btn btn-primary">Enviar</button>
          </div>
        </form>
      </div>
    </b-sidebar>
  </section>
  <!-- </div> -->
</template>

<script>
import { mapMutations } from "vuex";
import ContatoService from "../../services/contato.service";
import twilioService from "../../services/twilio.service";

export default {
  name: "ContatoView",
  props: ["id"],
  data() {
    return {
      contato: null,
      loading: false,
      numberWhatsApp: undefined,
      message: "",
      sociosFields: [
        {
          key: "nome",
          sortable: true,
        },
        {
          key: "socio_qualificacao.descricao",
          label: "Qualificação",
          sortable: true,
        },
        "data_entrada",
      ],
      emailFields: ["nome", "email", { key: "valido", label: "Status" }],
      contatosFields: [
        {
          key: "nome",
          sortable: true,
        },
        {
          key: "cargo",
          sortable: true,
        },
        {
          key: "departamento.nome",
          sortable: true,
          label: "Depto.",
        },
        {
          key: "nivel_hierarquico.nome",
          sortable: true,
          label: "Nível",
        },
        {
          key: "contatos",
          sortable: false,
          label: "Contatos",
        },
      ],
      template: [],

      selected: null,
      options: [
        // { value: null, text: "Please select an option" },
        // { value: "a", text: "This is First option" },
        // { value: "b", text: "Selected Option" },
        // { value: { C: "3PO" }, text: "This is an option with object value" },
        // { value: "d", text: "This one is disabled", disabled: true },
      ],
    };
  },
  created() {
    this.fetchData();
  },
  watch: {
    // call again the method if the route changes
    $route: "fetchData",
    selected() {
      //  console.log("mudou");
      this.message = this.selected;
    },
  },
  methods: {
    ...mapMutations(["setCall", "setPhone"]),

    fetchData() {
      this.contato = null;
      this.loading = true;
      ContatoService.get(this.id)
        .then((response) => {
          this.contato = response.data;
        })
        .catch(() => {})
        .then(() => {
          this.loading = false;
        });
    },

    call(number) {
      //alert(fone)
      //console.log(number);
      this.setCall(true);
      // this.setPhone(number.slice(2));
      this.setPhone(number);
    },
    setNumberForWhatsApp(number) {
      // verifica se o codigo do pais existe (55)
      if (number[0] != 5 && number[1] != 5) {
        // alert("sem codigo do pais")

        this.numberWhatsApp = "55" + number;
        //console.log(this.numberWhatsApp);
      } else {
        //console.log(this.numberWhatsApp);
        this.numberWhatsApp = number;
      }
    },

    sendWhatsApp() {
      if (this.message.trim() === "") {
        return alert("Mensagem vazia");
      }

      // console.log(this.message);
      // console.log(this.numberWhatsApp);

      twilioService
        .whatsappsend(this.message, this.numberWhatsApp)

        .then(() => {
          console.log("enviado");
          this.messageSuccess();

          //reseta inputs
          this.message = "";
          this.selected = null;
        })
        .catch((e) => {
          console.log(e);
          this.messageErro();
        });
    },

    messageSuccess() {
      this.$bvToast.toast(`Mensagem Enviada para ${this.numberWhatsApp}`, {
        title: `WhatsApp!`,
        variant: "success",
        solid: true,
      });
    },

    messageErro() {
      this.$bvToast.toast(
        `Erro ao tentar enviar mensagem para ${this.numberWhatsApp}`,
        {
          title: `WhatsApp!`,
          variant: "danger",
          solid: true,
        }
      );
    },

    getTemplates() {
      twilioService
        .getTemplates()
        .then((res) => {
          // console.log(res.data.templates);
          //this.templates = res.data.templates;
          this.options = res.data.templates;
          // console.log(this.options);
        })
        .catch((e) => {
          console.log(e);
          alert("Erro ao obter lista de templates");
        });
    },
  },

  mounted() {
    this.getTemplates();
  },
};
</script>


<style lang="scss" >
#sidebar-for-send-whatsapp {
  width: 40%;
  background: #191c24 !important;
  box-shadow: 0 0 35px -3px #000;
  border-top-left-radius: 6px;
  transition: 0.4s;

  textarea {
    height: 250px;
  }
}
</style>


<style lang="scss" scoped>
.form-control:disabled,
.form-control[readonly] {
  background: transparent;
}

.list-group-item-info {
  background-color: transparent;

}



</style>
